import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getFavorites } from '../../redux/favoriteDuck'
import ItemDetail from './item.detail';
import TemplateMenu from '../../template/menu.template';


const FavoritePage = ({ getFavorites, fetching_list, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getFavorites()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='mt-3'>
                    <Col className='text-center'><h3>Mis Favoritos</h3></Col>
                </Row>
                <Row>
                    <Col>
                        {(list.length > 0) ? list.map(d => <ItemDetail key={d.id} item={d} />) : <p className='text-center'>No tienes Favoritos</p>}
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { favorite } = props

    return {
        data: favorite.data,
        fetching_list: favorite.fetching_list,
    }
}

export default connect(mapsState, { getFavorites })(FavoritePage);