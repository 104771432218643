import React from 'react'

const ButtonAddRemove = ({ quantity, onRemove, onAdd }) => {
    return (
        <div className='btn-add-remove btn-add-remove-black'>
            <div className='btn-icon cursor-pointer' onClick={onRemove}>-</div>
            <div className='btn-icon'>{quantity}</div>
            <div className='btn-icon cursor-pointer' onClick={onAdd}>+</div>
        </div>
    )
}

export default ButtonAddRemove;