import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils";
import logo_mana_white from "../../images/logo_mana_white.png";

const API_URL = process.env.REACT_APP_API_URL;

const ItemProduct = ({ item }) => {
  let { modifier_group } = item;

  //PENDIND: pendiene
  // let _default = modifier_group.filter((f) => f.required === true)[0];

  // if (_default !== undefined) {
  //   let { modifiers_product } = _default;

  //   let _f = modifiers_product.filter((f) => f.default === true)[0];

  //   price = _f.price;
  // }


  return (
    <Col xs={12} className="mt-2 mx-0 px-0">
      <Card className="card-product-category">
        <Link className="link-category" to={`/producto/${item.slug}`}>
          <Row>
            <Col xs={4} className="d-flex align-items-center justify-content-center px-1">
              <img src={item.image} className="img-fluid rounded" />
            </Col>
            <Col xs={8}>
              <Row>
                <Col>
                  <span className="title-product text-capitalize">
                    {item.title}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className='"d-flex align-items-center height-description mr-1'>
                  <span className="font-size-12">{item.description_eco}</span>
                </Col>
              </Row>
              <Row>
                <Col className='"d-flex align-items-center height-description mr-1'>
                  <span className="font-size-12">${formatNumber(item.price)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Link>
      </Card>
    </Col>
  );
};

export default ItemProduct;
