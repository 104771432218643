import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineCaretDown, AiOutlineShoppingCart } from 'react-icons/ai';
import { MdOutlineMenu } from 'react-icons/md';
import { FiHome } from 'react-icons/fi';
import menu from '../images/menu.png';
import { Link } from 'react-router-dom';

const MenuSegment = ({ goToSeleccion, onToggleDrawable, title }) => {
    return (
        <nav className='sticky-top main-header'>
            <Container fluid className='pl-2 pt-2 pb-2'>
                <Row>
                    <Col xs={2} lg={1} className="d-flex justify-content-start align-items-center pl-3">
                        <MdOutlineMenu size={30} onClick={() => onToggleDrawable()} className="cursor-pointer" />
                        {/* <img src={menu} className="img-fluid img-menu cursor-pointer" onClick={() => onToggleDrawable()} /> */}
                        {/* <FaHamburger color='rgb(255, 138, 0)' size={32} onClick={() => onToggleDrawable()} /> */}
                    </Col>
                    <Col xs={8} lg={10} className="d-flex justify-content-center align-items-center" onClick={goToSeleccion}>
                        <FiHome color='fd7e14' />
                        <span className='title-main-menu mx-2'>{title}</span>
                        <AiOutlineCaretDown color='fd7e14' />
                    </Col>
                    <Col xs={2} lg={1} className="d-flex justify-content-end">
                        <Link to="/carrito" >
                            <AiOutlineShoppingCart color='grey' size={25} />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </nav>
    )
}

export default MenuSegment;