import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import info2 from '../../images/info_2.png'
import { getCouponByBranch } from "../../redux/discountDuck";
const API_URL = process.env.REACT_APP_API_URL


const ConfirmationDiscountPage = ({ branch_office, fetching_list, getCouponByBranch, row, fetching_coupon }) => {
  const history = useHistory();
  const [selected, setSelected] = useState({});
  const [listBranch, setListBranch] = useState([]);
  const [mainImage, setMainImage] = useState(info2)

  let { branch } = useParams();

  useEffect(() => {
    if (fetching_coupon === 2) {

      if (row.confirmation_image !== null) {
        let image = API_URL + row.confirmation_image
        console.log('image', image);

        setMainImage(image)
        console.log('row', row);
      }


    }
  }, [fetching_coupon]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListBranch(branch_office);

      branch = branch === 'campina' ? 'campiña' : branch;

      let _filter = branch_office.filter(b => b.title.toLowerCase() === branch.toLowerCase())[0];
      setSelected(_filter);
      getCouponByBranch(_filter)
    }
  }, [fetching_list]);


  return (
    <Container fluid className="bg-discount h-100">
      <Row className="pt-5">
        <Col>
          <Row className="d-flex justify-content-center">
            <Col className="text-center text-success" lg={12} md={5}>
              <img src={mainImage} className="img-fluid" />
            </Col>
          </Row>
          <Row >
            <Col className="text-center">
              <a href="http://suba.dontelmo.co/">Ir al Menu</a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapsState = (props) => {
  let { discount, branch_office, parameter } = props;

  return {
    fetching_list: branch_office.fetching_list,
    branch_office: branch_office.data,
    row: discount.row,
    fetching_coupon: discount.fetching_coupon,


  }
}

export default connect(mapsState, { getCouponByBranch })(ConfirmationDiscountPage);
