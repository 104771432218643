import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL


const ItemDetail = ({ item }) => {
    let {product}= item
    console.log('item',item);
    let img = API_URL + product.image
    
    return (

        <Row>
            <Col xs={3} className="px-1">
                <img src={img} className="img-fluid rounded" />
            </Col>
            <Col>
                <Row>
                    <Col>{product.title}</Col>
                </Row>
                <Row>
                    <Col>{product.description}</Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ItemDetail;