import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import follow_network from '../../images/follow_network.png'
import logo_dontel_orange from '../../images/logo_dontel_orange.svg'
import logo_dontelmo_white from '../../images/logo_dontelmo_white.svg'
import speak_with_our from '../../images/speak_with_our.png'
import whatsapp from '../../images/whatsapp.svg'
import './index.css'

const NetworkingPage = () => {
    return (
        <Container fluid className='background-network h-100'>
            <Row className='py-lg-5'>
                <Col lg={6}>
                    <Row className='d-flex justify-content-center my-lg-5 my-3'>
                        <Col lg={6}>
                            <Row className='my-lg-5 my-3 d-flex justify-content-center'>
                                <Col xs={6} lg={12}>
                                    <div className='follow_network' />
                                </Col>
                            </Row>
                            <Row className='my-lg-5 my-4 d-flex justify-content-center'>
                                <Col xs={4}>
                                    <a href='https://www.instagram.com/dontelmo1958/?igshid=YmMyMTA2M2Y%3D'>
                                        <img src={logo_dontel_orange} className="img-fluid" />
                                    </a>

                                </Col>
                                <Col xs={4}>
                                    <a href='https://www.instagram.com/manaexpress2008/?igshid=YmMyMTA2M2Y%3D'>
                                        <img src={logo_dontelmo_white} className="img-fluid" />
                                    </a>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center my-4'>
                                <Col lg={9} xs={6}>
                                    <img src={speak_with_our} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-4'>
                                <Col lg={4} xs={3}>
                                    <img src={whatsapp} className="img-fluid img-logo" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </Container>
    )
}

export default NetworkingPage;