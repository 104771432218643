import React from 'react'

const ButtonAddRemoveBlack = ({ quantity, onRemove, onAdd }) => {
    return (
        <div className='btn-add-remove btn-add-remove-white'>
            <div className='btn-icon cursor-pointer btn-icon-white' onClick={onRemove}>-</div>
            <div className='btn-icon btn-icon-white'>{quantity}</div>
            <div className='btn-icon cursor-pointer btn-icon-white' onClick={onAdd}>+</div>
        </div>
    )
}

export default ButtonAddRemoveBlack;