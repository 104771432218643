import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemDetail = ({ item }) => {
    let {branch_office} =item
    return (

        <Row className='border-top'>
            <Col xs={3}>{item.id}</Col>
            <Col>
                <Row>
                    <Col>{item.address}</Col>
                </Row>
                <Row>
                    <Col>Tipo pedido: {item.type_delivery_text}</Col>
                </Row>
                <Row>
                    <Col>Tienda: {branch_office.title}</Col>
                </Row>
                <Row>
                    <Col>Cantidad: {item.quantity}</Col>
                </Row>
                <Row>
                    <Col>Total: ${formatNumber(item.total)}</Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ItemDetail;