import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getProductSlug, selectedTempModifier, selectedTempModifierOptional, addQuantityTemp } from '../../redux/productDuck'
import { onAddItem } from '../../redux/cartDuck'
import { setFavoriteUser } from '../../redux/favoriteDuck'
import logo_madera from '../../images/logo_madera.png'
import { FaAngleLeft } from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import ItemGroup from './item.group';
import { useHistory } from 'react-router-dom';
import { formatNumber } from '../../utils';
import ButtonAddRemoveBlack from '../../components/button_add_remove_black';

const API_URL = process.env.REACT_APP_API_URL

const ProductPage = ({ getProductSlug, data, fetching_slug, setFavoriteUser, selectedTempModifier, selectedTempModifierOptional, addQuantityTemp, onAddItem,
    fetching_item, fetching_favorite, row_favorite, loggedIn }) => {
    let { slug } = useParams()
    const [modifiers, setModifiers] = useState([])
    const [image, setImage] = useState(logo_madera)
    const [favorite, setFavorite] = useState(false)
    const [price, setPrice] = useState(null)
    const history = useHistory()

    useEffect(() => {
        if (fetching_slug === 2) {

            setImage(data.image)
            setModifiers(data.modifier_group);
            setPrice(data.price)
            if (data.favorite === null) {
                setFavorite(false)
            } else {
                setFavorite(true)
            }
        }
    }, [fetching_slug])

    useEffect(() => {
        if (fetching_favorite === 2) {
            if (row_favorite.status !== true) {
                setFavorite(false)
            } else {
                setFavorite(true)
            }
        }
    }, [fetching_favorite])

    useEffect(() => {
        if (fetching_item === 2) {
            history.goBack()
        }
    }, [fetching_item])

    useEffect(() => {
        getProductSlug(slug)
    }, [slug])


    const onSelectModifier = (group, modifier) => {
        if (group.type_input !== 2) {
            selectedTempModifier(group, modifier)
        } else {
            selectedTempModifierOptional(group, modifier)
        }
    }

    const onAddProduct = () => {
        data.quantity = data.quantity + 1
        addQuantityTemp(data)
    }
    const onRemoveProduct = () => {
        data.quantity = data.quantity - 1

        if (data.quantity === 0) {
            history.goBack()
        }

        addQuantityTemp(data)
    }

    const onSelectFavorite = (item) => {
        if (loggedIn) {
            setFavoriteUser(item)
        } else {
            alert("No estas logueado")
        }
    }

    return (
        <Container fluid className='mx-0 bg-grey'>
            <Row>
                <Col className='mx-0 px-0'>
                    <div className='image-product' style={{ backgroundImage: `url(${image})` }}>
                        <a href="#" id="back-category" onClick={() => history.goBack()}>
                            <FaAngleLeft color='fff' size={30} />
                        </a>
                        <div id='title-category'>{data.title}</div>
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className='mx-0 ml-2'>
                    <Row>
                        <Col xs={10} className='title-ingredients'>Ingredientes</Col>
                        <Col>
                            {favorite
                                ? <AiFillHeart size={30} onClick={() => onSelectFavorite(data)} />
                                : <AiOutlineHeart size={30} onClick={() => onSelectFavorite(data)} />}
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col className='text-description-product font-size-14'>{data.description}</Col>
                    </Row>
                    {(modifiers.length > 0) ? modifiers.map(row => <ItemGroup item={row} key={row.id} onClick={onSelectModifier} />)
                        : <Row className='my-2'>
                            <Col>${price !== null ? formatNumber(price) : 0}</Col>
                        </Row>}
                </Col>
            </Row>

            <Row className='fixed-bottom bg-black-9 justify-content-xs-start justify-content-lg-center align-items-center'>
                <Col xs={6} className="py-3">
                    <div className='content-button'>
                        <Row>
                            <Col xs={4} className="ml-2">
                                <ButtonAddRemoveBlack quantity={data.quantity} onRemove={onRemoveProduct} onAdd={onAddProduct} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={6} className="text-right justify-content-end d-flex pr-4">
                    <button className='btn btn-add' onClick={onAddItem}>Agregar <span className='ml-2'>${formatNumber(parseFloat(data.total))}</span></button>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { product, cart, favorite, user } = props

    return {
        fetching_slug: product.fetching_slug,
        data: product.data,
        fetching_item: cart.fetching_item,
        fetching_favorite: favorite.fetching_save,
        row_favorite: favorite.row,
        loggedIn: user.loggedIn
    }
}

export default connect(mapsState, {
    getProductSlug, setFavoriteUser, selectedTempModifier, selectedTempModifierOptional, addQuantityTemp,
    onAddItem
})(withRouter(ProductPage));