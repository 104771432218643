import React, { useEffect } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo_white from '../../images/logo_mana_white.png'
import { onSelectDelivery } from '../../redux/branchOfficeDuck'

const OptionsPage = ({ onSelectDelivery, fetching_option, path }) => {
    const history = useHistory()



    useEffect(() => {
        if (fetching_option === 2) {
            history.push(`/${path}`);
        }
    }, [fetching_option])

    const goToSelection = (path) => {
        onSelectDelivery(path)
    }

    return (
        <Container fluid className='mx-0 px-0'>
            <div className='bg-main'>
                <div className='bg-burger-before'>
                    <div className='container-header-location'>
                        <Container fluid>
                            <Row className='justify-content-center pt-5'>
                                <Col xs={5} lg={2}>
                                    <img className='img-fluid' src={logo_white} />
                                </Col>
                            </Row>
                            <Row className='mt-5 d-flex justify-content-center'>
                                <Col lg={12} xs={8}>
                                    <Carousel>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="holder.js/800x400?text=First slide&bg=373940"
                                                alt="First slide"
                                            />
                                            <Carousel.Caption>
                                                <h3>First slide label</h3>
                                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="holder.js/800x400?text=Second slide&bg=282c34"
                                                alt="Second slide"
                                            />

                                            <Carousel.Caption>
                                                <h3>Second slide label</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src="holder.js/800x400?text=Third slide&bg=20232a"
                                                alt="Third slide"
                                            />

                                            <Carousel.Caption>
                                                <h3>Third slide label</h3>
                                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='container-footer-option'>
                        <Container fluid>
                            <Row>
                                <Col className='text-center'><h5 className='text-white'>¿Que deseas hacer hoy?</h5></Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-3'>
                                <Col lg={2} xs={6}>
                                    <button className='btn btn-info form-control' onClick={() => goToSelection("domicilio")}>A Domicilio</button>
                                </Col>
                                <Col lg={2} xs={6}>
                                    <button className='btn btn-success form-control' onClick={() => goToSelection("delivery-takeout")}>Para Recoger</button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>

        </Container>
    )
}

const mapsState = (props) => {
    return {
        fetching_option: props.branch_office.fetching_option,
        path: props.branch_office.path
    }
}

export default connect(mapsState, { onSelectDelivery })(OptionsPage);