import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { onCreateCourtesy, getCouponByBranch } from "../../redux/discountDuck";
import { getParameterSegmentOut } from "../../redux/parameterDuck";
import info1 from "../../images/info_1.png";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

const CourtesyPage = ({
  errors,
  onCreateCourtesy,
  fetching_create,
  fetching_list,
  branch_office,
  error,
  getParameterSegmentOut,
  fetching_list_parameter,
  data,
  getCouponByBranch,
  fetching_coupon,
  row,
}) => {
  const [form, setForm] = useState({
    name: "",
    phone: "",
    branch_id: 0,
    date_reservation: "",
  });

  console.log("error", error);
  const [mainImage, setMainImage] = useState(info1);

  const history = useHistory();
  const [listBranch, setListBranch] = useState([]);
  const [listSurvey, setListSurvey] = useState([]);
  const [selected, setSelected] = useState({});

  let { branch } = useParams();

  console.log("fetching_coupon", fetching_coupon);

  useEffect(() => {
    if (fetching_coupon === 2) {
      if (row.main_image !== null) {
        let image = API_URL + `/app/registro.png`;
        console.log("image", image);

        setMainImage(image);
        console.log("row", row);
      }
    }
  }, [fetching_coupon]);

  useEffect(() => {
    if (fetching_create === 2) {
      history.push(`/cortesia-confirmacion/${branch}`);
    }
  }, [fetching_create]);

  useEffect(() => {
    if (fetching_list_parameter === 2) {
      setListSurvey(data);
    }
  }, [fetching_list_parameter]);

  useEffect(() => {
    getParameterSegmentOut("survey");
  }, []);

  useEffect(() => {
    if (fetching_list === 2) {
      setListBranch(branch_office);

      branch = branch === "campina" ? "campiña" : branch;

      let _filter = branch_office.filter(
        (b) => b.title.toLowerCase() === branch.toLowerCase()
      )[0];
      setSelected(_filter);
      getCouponByBranch(_filter);
    }
  }, [fetching_list]);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let _form = form;
    _form.branch_id = selected.id;

    onCreateCourtesy(_form);
  };

  return (
    <Container fluid className="bg-discount">
      <Form onSubmit={onSubmit}>
        <Row className="d-flex justify-content-center">
          <Col lg={4}>
            <Row>
              <Col className="d-flex justify-content-center">
                <img src={mainImage} className="img-fluid" />
              </Col>
            </Row>
            {error !== undefined && (
              <Row className="my-2">
                <Col className="text-center">
                  <h4 className="text-danger text-upper">{error}</h4>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="label-discount">
                    Nombre Completo
                  </Form.Label>
                  <Form.Control
                    className="input-sm"
                    type="text"
                    placeholder="Nombre Completo"
                    name="name"
                    onChange={updateInput}
                    value={form.name}
                  />
                  {errors.name !== undefined ? (
                    <label className="text-danger ">{errors.name}</label>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="label-discount">Celular</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Celular"
                    name="phone"
                    onChange={updateInput}
                    value={form.phone}
                  />
                  {errors.phone !== undefined ? (
                    <label className="text-danger ">{errors.phone}</label>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="label-discount">Código</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Código"
                    name="code"
                    onChange={updateInput}
                    value={form.code}
                  />
                  {errors.code !== undefined ? (
                    <label className="text-danger ">{errors.code}</label>
                  ) : null}
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="label-discount">
                    Fecha de Reserva
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="dia/Mes/Año"
                    name="date_reservation"
                    onChange={updateInput}
                    value={form.date_reservation}
                    min={moment().format("YYYY-MM-DD")}
                  />
                  {errors.address !== undefined ? (
                    <label className="text-danger ">
                      {errors.registration_date}
                    </label>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-2 mb-5">
              <Col lg={6}>
                <button
                  type="submit"
                  className="btn btn-success form-control btn-custom"
                >
                  Enviar
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const mapsState = (props) => {
  let { discount, branch_office, parameter } = props;

  return {
    errors: discount.errors,
    error: discount.error,
    fetching_create: discount.fetching_create,
    fetching_coupon: discount.fetching_coupon,
    row: discount.row,
    branch_office: branch_office.data,
    fetching_list: branch_office.fetching_list,
    fetching_list_parameter: parameter.fetching_list,
    data: parameter.data,
  };
};

export default connect(mapsState, {
  onCreateCourtesy,
  getParameterSegmentOut,
  getCouponByBranch,
})(CourtesyPage);
