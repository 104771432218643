import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Dot from '../../components/dot';
import ItemModifier from './item.modifier';
import ItemOptions from './item.options';

const ItemGroup = ({ item, onClick }) => {
    let { modifiers_product } = item

    const onClickTmp = (modifier) => {
        onClick(item, modifier)
    }

    return (
        <>
            <Row className='my-2'>
                <Col xs={9} className='title-group-modifier'>{item.title}</Col>
                <Col xs={3}>
                    <ItemOptions required={item.required} />
                </Col>
            </Row>
            {modifiers_product.map((row, k) => <ItemModifier key={k} item={row} type_input={item.type_input} onClick={onClickTmp} />)}
        </>

    )
}

export default ItemGroup;