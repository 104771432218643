import { getHeaders, validateInput } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_create: 0,
  fetching_coupon: 0,
  errors: {},
  data: [],
  row: {},
  error: "",
};

const CREATE_DISCOUNT = "CREATE_DISCOUNT";
const CREATE_DISCOUNT_SUCCESS = "CREATE_DISCOUNT_SUCCESS";
const CREATE_DISCOUNT_ERROR = "CREATE_DISCOUNT_ERROR";
const CREATE_DISCOUNT_ERROR_VALID = "CREATE_DISCOUNT_ERROR_VALID";
const COUPON_BRANCH = "COUPON_BRANCH";
const COUPON_BRANCH_SUCCESS = "COUPON_BRANCH_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_DISCOUNT:
      return { ...state, fetching_create: 1 };
    case CREATE_DISCOUNT_SUCCESS:
      return { ...state, fetching_create: 2 };
    case CREATE_DISCOUNT_ERROR:
      return { ...state, fetching_create: 3, errors: action.payload };
    case CREATE_DISCOUNT_ERROR_VALID:
      return { ...state, fetching_create: 4, error: action.payload };
    case COUPON_BRANCH:
      return { ...state, fetching_coupon: 1 };
    case COUPON_BRANCH_SUCCESS:
      return { ...state, fetching_coupon: 2, row: action.payload };
    default:
      return state;
  }
}

export let getCouponByBranch = (branch) => (dispatch) => {
  dispatch({
    type: COUPON_BRANCH,
  });

  fetch(`${API_URL}/api/coupon-branch/${branch.id}`, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      console.log("resp", resp);

      dispatch({
        type: COUPON_BRANCH_SUCCESS,
        payload: resp,
      });
    });
};

export let onCreateDiscount = (form) => (dispatch) => {
  dispatch({
    type: CREATE_DISCOUNT,
  });

  console.log("form", form);

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "phone", msg: "El celular es requerido", maxlength: 10 },
    { field: "email", msg: "El email es requerido" },
    { field: "address", msg: "La dirección es requerido" },
  ];

  let validate = validateInput(form, rules);

  if (form.email !== form.confirmation) {
    if (validate === null) {
      validate = { confirmation: "Confirmación no coincide con email" };
    } else {
      validate.confirmation = "Confirmación no coincide con email";
    }
  }

  if (validate !== null) {
    dispatch({
      type: CREATE_DISCOUNT_ERROR,
      payload: validate,
    });
    return;
  }

  fetch(`${API_URL}/api/discount`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.status === false) {
        if (resp.error !== undefined) {
          dispatch({
            type: CREATE_DISCOUNT_ERROR_VALID,
            payload: resp.error,
          });
        } else {
          dispatch({
            type: CREATE_DISCOUNT_ERROR,
            payload: resp.errors,
          });
        }
      } else {
        dispatch({
          type: CREATE_DISCOUNT_SUCCESS,
        });
      }
    });
};

export let onCreateCourtesy = (form) => (dispatch) => {
  dispatch({
    type: CREATE_DISCOUNT,
  });

  console.log("form", form);

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "phone", msg: "El celular es requerido", maxlength: 10 },
    { field: "registration_date", msg: "La fecha de registro es requerido" },
  ];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: CREATE_DISCOUNT_ERROR,
      payload: validate,
    });
    return;
  }

  fetch(`${API_URL}/api/courtesy-validate`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.status === false) {
        if (resp.error !== undefined) {
          dispatch({
            type: CREATE_DISCOUNT_ERROR_VALID,
            payload: resp.error,
          });
        } else {
          dispatch({
            type: CREATE_DISCOUNT_ERROR,
            payload: resp.errors,
          });
        }
      } else {
        dispatch({
          type: CREATE_DISCOUNT_SUCCESS,
        });
      }
    });
};
export let onValidateDiscount = (form) => (dispatch) => {
  dispatch({
    type: CREATE_DISCOUNT,
  });

  console.log("form", form);

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "phone", msg: "El celular es requerido", maxlength: 10 },
    { field: "code", msg: "La Código es requerido" },
  ];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: CREATE_DISCOUNT_ERROR,
      payload: validate,
    });
    return;
  }

  fetch(`${API_URL}/api/discount-validate`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.status === false) {
        if (resp.error !== undefined) {
          dispatch({
            type: CREATE_DISCOUNT_ERROR_VALID,
            payload: resp.error,
          });
        } else {
          dispatch({
            type: CREATE_DISCOUNT_ERROR,
            payload: resp.errors,
          });
        }
      } else {
        dispatch({
          type: CREATE_DISCOUNT_SUCCESS,
        });
      }
    });
};
