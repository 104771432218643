import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FcCheckmark } from "react-icons/fc";


const ConfirmationPage=()=>{
    return (
        <Container fluid>
            
            <Row className='mt-5 text-center pt-5'>
                <Col><h3>Gracias por tu Compra</h3></Col>
            </Row>
            <Row className='my-5 text-center'>
                <Col>
                    <FcCheckmark size={150}/>
                </Col>
            </Row>
            <Row className='text-center'>
                <Col>
                    <Link to="/menu" className="btn btn-success">Ir al Menu</Link>
                </Col>
            </Row>
            
        </Container>
    )
}

export default ConfirmationPage;