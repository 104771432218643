let initialState = {
    fetching: 0,
    title: 'SELECCIONA'
}


const HEADER = "HEADER"
const HEADER_SUCCESS = "HEADER_SUCCESS"



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HEADER: {
            return { ...state, fetching: 1 }
        }
        case HEADER_SUCCESS: {
            return { ...state, fetching: 2, title: action.payload, branch_office: action.branch_office }
        }
        default:
            return state;
    }
}

export let getHeader = () => dispatch => {
    dispatch({
        type: HEADER
    })

    let storage = localStorage.getItem("storage")

    if (storage !== null) {

        storage = JSON.parse(storage)

        let { branch, delivery } = storage

        if (delivery === 1) {
            dispatch({
                type: HEADER_SUCCESS,
                payload: "SELECCIONE"
            })
        } else {
            if(branch!==undefined){
                dispatch({
                    type: HEADER_SUCCESS,
                    payload: branch.title,
                    branch_office: branch
                })
            }
            
        }
    }
}