import React from 'react'
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import FormLoginSegment from './form.login.segment';
import FormRegisterSegment from './form.register.segment';

const SegmentLoginRegister = ({ onSuccess,errors }) => {
    return (
        <Row className='my-2'>
            <Col>

                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Inicia Sesión">
                        <FormLoginSegment onSuccess={onSuccess} errors={errors}/>
                    </Tab>
                    <Tab eventKey="profile" title="Registrate">
                        <FormRegisterSegment errors={errors}/>
                    </Tab>
                </Tabs>


            </Col>
        </Row>
    )
}

export default SegmentLoginRegister;
