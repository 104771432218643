import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';

const FormLoginSegment = ({ onSuccess, errors }) => {
    const [form, setForm] = useState({ email_login: '', password_login: '' })

    const onSubmit = (e) => {
        e.preventDefault();
        onSuccess(form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Correo y/o Celular</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" name="email_login" onChange={updateInput} value={form.user} />
                        {errors.email_login !== undefined ? (<label className="text-danger ">{errors.email_login}</label>) : null}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password_login" placeholder="ingresa tu clave" onChange={updateInput} value={form.password} />
                        {errors.password_login !== undefined ? (<label className="text-danger ">{errors.password_login}</label>) : null}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Button variant="primary" type="submit">
                        Inicia Sesión
                    </Button>
                </Col>
            </Row>




        </Form>
    )
}

export default FormLoginSegment;