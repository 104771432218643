import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';

const ModalCancelCart = ({ show, onHide, onSuccess }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Row>
                    <Col className='text-center'><h5>¿Realmente deseas vaciar el carrito?</h5></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="success" onClick={onSuccess}>Vaciar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCancelCart;