import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getFavorites } from '../../redux/favoriteDuck'
import ItemDetail from './item.detail';
import TemplateMenu from '../../template/menu.template';


const AddressPage = ({ getFavorites, fetching_favorite, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getFavorites()
    }, [])

    useEffect(() => {
        if (fetching_favorite === 2) {
            setList(data)
        }
    }, [fetching_favorite])

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='mt-3'>
                    <Col className='text-center'><h3>Mis Direcciones</h3></Col>
                </Row>
                <Row>
                    <Col>
                        {list.map(d => <ItemDetail key={d.id} item={d} />)}
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product } = props
    return {
        data: product.data_favorite,
        fetching_favorite: product.fetching_favorite,
    }
}

export default connect(mapsState, { getFavorites })(AddressPage);