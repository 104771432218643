import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCartHistory } from '../../redux/cartDuck'
import ItemDetail from './item.detail';
import TemplateMenu from '../../template/menu.template';


const OrderPage = ({ getCartHistory, fetching_history, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getCartHistory()
    }, [])

    useEffect(() => {
        if (fetching_history === 2) {
            setList(data)
        }
    }, [fetching_history])

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='mt-3'>
                    <Col className='text-center'><h3>Historial de Pedidos</h3></Col>
                </Row>
                <Row>
                    <Col>
                        {(list.length>0)?list.map(d => <ItemDetail key={d.id} item={d} />):<p className='text-center'>No Tienes Pedidos</p>}
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { cart } = props
    return {
        data: cart.data,
        fetching_history: cart.fetching_history,
    }
}

export default connect(mapsState, { getCartHistory })(OrderPage);