import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ItemOptions = ({ required }) => {
    return (required === true) ? <Row><Col className='pl-0 text-white text-right pr-0 justify-content-end d-flex align-items-center font-size-11'>
        <span className='title-options-required font-size-12'>Requerido*</span></Col></Row> :
        <Row className='text-right'>
            <Col className='font-size-12 title-optional'>Opcional</Col>
        </Row>
}

export default ItemOptions;