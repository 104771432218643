import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCategories } from '../../redux/categoryDuck'
import { getBanners } from '../../redux/bannerDuck'

import { onResetChoose } from '../../redux/branchOfficeDuck'
import { getHeader } from '../../redux/headerDuck'
import ItemCategory from './item.categories';
import { useHistory } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template';
import CheckSelected from './check_selected';


const HomePage = ({ getCategories, categories, drawer, getHeader, onResetChoose, title, getBanners, fetching_list, data_banner }) => {
    const history = useHistory()

    const [typeOrder, setTypeOrder] = useState({ title: 'Recoger', type: 1 })

    const [listBanner, setListBanner] = useState([])

    useEffect(() => {
        onResetChoose()
        getCategories()
        getHeader()
        getBanners()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListBanner(data_banner)
        }
    }, [fetching_list])


    const onChangeType = () => {
        setTypeOrder({ type: typeOrder.type === 1 ? 2 : 1, title: typeOrder.type === 1 ? "Recoger" : 'Domicilio' })
    }


    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='d-flex justify-content-center my-3'>
                    <Col xs={5} lg={1}>
                        <CheckSelected typeOrder={typeOrder} onClick={onChangeType} />
                    </Col>
                </Row>
                <div className='my-2 container-banner' >
                    {listBanner.map((b, k) =>
                        <div className='item-banner' key={k} style={{ backgroundImage: `url(${b.real_image})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}></div>
                    )}

                </div>
                <Row className='d-flex justify-content-center'>
                    <Col lg={10} xs={12} className="px-1 mx-1">
                        <Row className='mt-3 mx-1'>
                            {categories.map((row, k) => <ItemCategory item={row} key={k} />)}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { banner, category } = props

    return {
        categories: category.data,
        fetching_list: banner.fetching_list,
        data_banner: banner.data

    }
}

export default connect(mapsState, { getCategories, getHeader, onResetChoose, getBanners })(HomePage);