import { getHeaders } from '../utils'
import { logOutAction } from './userDuck'
import uuid from 'react-uuid';


const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching: 0,
    fetching_select: 0,
    fetching_item: 0,
    fetching_quantity: 0,
    fetching_payment: 0,
    fetching_modify: 0,
    fetching_clean: 0,
    fetching_history: 0,
    quantity: 0,
    quantity_temp: 0,
    selected: {},
    total: 0,
    data: []
}


const LIST_CART = "LIST_CART"
const LIST_CART_SUCCESS = "LIST_CART_SUCCESS"
const SELECT_CART = "SELECT_CART"
const SELECT_CART_SUCCESS = "SELECT_CART_SUCCESS"
const SELECT_MODIFIER = "SELECT_MODIFIER"
const ADD_ITEM = "ADD_ITEM"
const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS"
const CART_REVIEW = "CART_REVIEW"
const CART_REVIEW_SUCCESS = "CART_REVIEW_SUCCESS"
const LIST_PAYMENT_METHOD = "LIST_PAYMENT_METHOD"
const LIST_PAYMENT_METHOD_SUCCESS = "LIST_PAYMENT_METHOD_SUCCESS"
const MODIFY_ITEM = "MODIFY_ITEM"
const MODIFY_ITEM_SUCCESS = "MODIFY_ITEM_SUCCESS"
const CLEAN_CART = "CLEAN_CART"
const CLEAN_CART_SUCCESS = "CLEAN_CART_SUCCESS"
const LIST_HISTORY = "LIST_HISTORY"
const LIST_HISTORY_SUCCESS = "LIST_HISTORY_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_CART: {
            return { ...state, fetching_select: 1 }
        }
        case SELECT_CART_SUCCESS: {
            return { ...state, fetching_select: 2, selected: action.payload }
        }
        case ADD_ITEM: {
            return { ...state, fetching_item: 1 }
        }
        case ADD_ITEM_SUCCESS: {
            return { ...state, fetching_item: 2 }
        }
        case CART_REVIEW: {
            return { ...state, fetching_quantity: 1, fetching_item: 0, fetching_list: 0 }
        }
        case CART_REVIEW_SUCCESS: {
            return { ...state, fetching_quantity: 2, quantity: action.payload, total: action.total }
        }
        case LIST_CART: {
            return { ...state, fetching_list: 1, fetching_modify: 0 }
        }
        case LIST_CART_SUCCESS: {
            return { ...state, fetching_list: 2, data: action.payload }
        }
        case LIST_PAYMENT_METHOD: {
            return { ...state, fetching_payment: 1 }
        }
        case LIST_PAYMENT_METHOD_SUCCESS: {
            return { ...state, fetching_payment: 2, data_payment: action.payload }
        }
        case MODIFY_ITEM: {
            return { ...state, fetching_modify: 1, fetching_list: 0 }
        }
        case MODIFY_ITEM_SUCCESS: {
            return { ...state, fetching_modify: 2 }
        }
        case CLEAN_CART: {
            return { ...state, fetching_clean: 1 }
        }
        case CLEAN_CART_SUCCESS: {
            return { ...state, fetching_clean: 2, total: 0 }
        }
        case LIST_HISTORY: {
            return { ...state, fetching_history: 1 }
        }
        case LIST_HISTORY_SUCCESS: {
            return { ...state, fetching_history: 2, data: action.payload }
        }
        default:
            return state;
    }
}

export let getCart = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CART
    })

    let { branch, loggedIn } = getState().user;

    let storage = JSON.parse(localStorage.storage)

    let total = 0;
    let detail = []

    if (storage.cart !== undefined) {
        console.log('ingrsos');

        total = getDataCar(storage.cart).total

        console.log('total', total);

        detail = storage.cart;
    }

    dispatch({
        type: LIST_CART_SUCCESS,
        payload: { detail, total },

    })
}

export let getCartReview = () => (dispatch, getState) => {
    dispatch({
        type: CART_REVIEW
    })

    let storage = JSON.parse(localStorage.storage)
    let { cart } = storage

    let quantity = 0
    let total = 0


    console.log('cart', cart);


    if (cart !== undefined) {
        cart.map(c => {
            let { modifier_group } = c;

            if (modifier_group.length > 0) {
                modifier_group.map(m => {
                    let { modifiers_product } = m;
                    total += modifiers_product.filter(f => f.selected === true).reduce((total, { price }) => total + parseFloat(price), 0)
                })
            } else {
                total += parseFloat(c.price) * c.quantity;
            }


            quantity += c.quantity

        })
    }

    dispatch({
        type: CART_REVIEW_SUCCESS,
        payload: quantity,
        total
    })

}


let getDataCar = (cart) => {
    let quantity = 0
    let total = 0

    cart.map(c => {
        let { modifier_group } = c;
        if (modifier_group.length > 0) {
            modifier_group.map(m => {
                let { modifiers_product } = m;
                total += modifiers_product.filter(f => f.selected === true).reduce((total, { price }) => total + parseFloat(price), 0) * c.quantity
            })
        } else {
            total += parseFloat(c.price) * c.quantity
        }
        quantity += c.quantity

    })

    return { quantity, total }
}


export let getCartHistory = () => (dispatch, getState) => {
    dispatch({
        type: LIST_HISTORY
    })

    fetch(`${API_URL}/api/cart-history`, {
        headers: getHeaders(),
    }).then(async (data) => {
        if (data.ok) {
            data = await data.json()
            dispatch({
                type: LIST_HISTORY_SUCCESS,
                payload: data.results,
            })

        } else {
            let { status } = data;

            console.log('status', status);


            if (status === 404) {

            }
            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
        }
    }).catch(e => console.log('Connection error', e))
}

export let getTypeMethods = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYMENT_METHOD
    })

    fetch(`${API_URL}/api/cart-type-payment`, {
        headers: getHeaders(),
    }).then(async (data) => {
        if (data.ok) {
            data = await data.json()
            dispatch({
                type: LIST_PAYMENT_METHOD_SUCCESS,
                payload: data.results,
            })

        } else {
            let { status } = data;

            if (status === 404) {

            }


        }
    }).catch(e => console.log('Connection error', e))
}

export let onAddItem = () => (dispatch, getState) => {

    dispatch({
        type: ADD_ITEM
    })

    let { data } = getState().product;

    let storage = JSON.parse(localStorage.storage);

    let cart = []
    data._id = uuid();
    cart.push(data)


    if (storage.cart !== undefined) {
        storage.cart = cart.concat(storage.cart)
    } else {
        storage.cart = cart;
    }


    localStorage.storage = JSON.stringify(storage)

    dispatch({
        type: ADD_ITEM_SUCCESS
    })
}


export let onModifyItem = (item) => (dispatch, getState) => {

    dispatch({
        type: MODIFY_ITEM
    })

    let storage = JSON.parse(localStorage.storage)
    let { cart } = storage

    if (item.quantity === 0) {
        cart = cart.filter(c => c._id !== item._id)
    } else {
        cart = cart.map(c => {
            if (c._id === item._id) {
                c.quantity = item.quantity
            }
            return c;
        })
    }

    storage.cart = cart;

    localStorage.storage = JSON.stringify(storage);

    dispatch({
        type: MODIFY_ITEM_SUCCESS
    })
}

export let onCleanCart = () => (dispatch, getState) => {
    dispatch({
        type: CLEAN_CART
    })

    let storage = JSON.parse(localStorage.storage)

    storage.cart = [];

    localStorage.storage = JSON.stringify(storage)
    dispatch({
        type: CLEAN_CART_SUCCESS,
    })
}