import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const RegisterPage = ({ errors }) => {

    const [form, setForm] = useState({ user: '', password: '' })

    const onSubmit = () => { }
    const updateInput = (e) => { }

    return (
        <Container fluid>
            <Row className='my-5'>
                <Col>
                    <h2 className='text-center'>REGISTRO</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Correo y/o Celular</Form.Label>
                                    <Form.Control type="text" placeholder="Enter email" name="email_login" onChange={updateInput} value={form.user} />
                                    {errors.email !== undefined ? (<label className="text-danger ">{errors.email}</label>) : null}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password_login" placeholder="ingresa tu clave" onChange={updateInput} value={form.password} />
                                    {errors.password !== undefined ? (<label className="text-danger ">{errors.password}</label>) : null}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </Container>

    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        errors: user.errors
    }
}

export default connect(mapsState, {})(RegisterPage);