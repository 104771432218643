import { getHeaders } from "../utils"

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
}


const LIST_SEGMENT = "LIST_SEGMENT"
const LIST_SEGMENT_SUCCESS = "LIST_SEGMENT_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SEGMENT: {
            return { ...state, fetching_list: 1 }
        }
        case LIST_SEGMENT_SUCCESS: {
            return { ...state, fetching_list: 2, data: action.payload }
        }
        default:
            return state;
    }
}

export let getParameterSegment = (segment) => dispatch => {
    dispatch({
        type: LIST_SEGMENT
    })

    fetch(`${API_URL}/api/parameters/${segment}/segment`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_SEGMENT_SUCCESS,
            payload: resp.results
        })
    })
}

export let getParameterSegmentOut = (segment) => dispatch => {
    dispatch({
        type: LIST_SEGMENT
    })

    fetch(`${API_URL}/api/parameters-segment/${segment}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_SEGMENT_SUCCESS,
            payload: resp.results
        })
    })
}