import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_slug: 0,
    data: [],
    data_slug: {},
}



const LIST_CATEGORY = "LIST_CATEGORYT"
const LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS"
const CATEGORY_SLUG = "CATEGORY_SLUG"
const CATEGORY_SLUG_SUCCESS = "CATEGORY_SLUG_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORY:
            return { ...state, fetching_list: 1 }
        case LIST_CATEGORY_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CATEGORY_SLUG:
            return { ...state, fetching_slug: 1 }
        case CATEGORY_SLUG_SUCCESS:
            return { ...state, fetching_slug: 2, data_slug: action.payload }
        default:
            return state;
    }
}

export let getCategories = () => dispatch => {
    dispatch({
        type: LIST_CATEGORY
    })

    fetch(`${API_URL}/api/category-client`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_CATEGORY_SUCCESS,
            payload: resp.results
        })
    })
}

export let getCategoriesSlug = (slug) => dispatch => {
    dispatch({
        type: CATEGORY_SLUG
    })


    let { branch } = JSON.parse(localStorage.getItem("storage"))

    fetch(`${API_URL}/api/category/${slug}/${branch.id}`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CATEGORY_SLUG_SUCCESS,
            payload: resp.results
        })
    })
}