import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { MdArrowBackIosNew } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getCart, onModifyItem, onCleanCart } from '../../redux/cartDuck'
import { formatNumber } from '../../utils';
import ItemCart from './item.cart';
import ModalCancelCart from './modal.cancel';

const CartPage = ({ getCart, fetching_list, cart, onModifyItem, fetching_modify, onCleanCart, fetching_clean }) => {

    const [listCart, setListCart] = useState([])
    const [total, setTotal] = useState(0)
    const [modalCancel, setModalCancel] = useState(false)

    const history = useHistory();


    useEffect(() => {
        if (fetching_clean === 2) {
            setModalCancel(false)
            history.goBack()
        }
    }, [fetching_clean])

    useEffect(() => {
        if (fetching_list === 2) {
            setTotal(cart.total)
            setListCart(cart.detail)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_modify === 2) {
            getCart()
        }
    }, [fetching_modify])

    useEffect(() => {
        getCart()
    }, [])


    const onRemoveItem = (item) => {
        item.quantity = item.quantity - 1;
        onModifyItem(item)
    }

    const onAddItem = (item) => {
        item.quantity = item.quantity + 1;
        onModifyItem(item)
    }

    return (
        <Container fluid>
            <Row className='fixed-top border-bottom d-flex align-items-center bg-white mx-0 my-0'>
                <Col xs={1}>
                    <MdArrowBackIosNew onClick={() => history.goBack()} className="cursor-pointer" />
                </Col>
                <Col className='text-center pt-2 align-items-center d-flex justify-content-center pr-4'>
                    <h4>Tu Carrito</h4>
                </Col>
            </Row>

            <Row className='mt-5 pt-4'>
                <Col >
                    {(listCart.length > 0) ? listCart.map(item => <ItemCart item={item} key={item._id} onRemoveItem={onRemoveItem} onAddItem={onAddItem} />)
                        : <p className='text-center'>No tienes Productos Agregados</p>}
                </Col>
            </Row>
            <Row className='py-5'>
                <Col className='mt-5'>
                </Col>
            </Row>
            <Row className='fixed-bottom '>
                <Col xs={12}>
                    <Row className='bg-black-7'>
                        <Col className='d-flex justify-content-center py-2'>
                            <button className='button-style text-white' onClick={() => setModalCancel(true)}>Vaciar Carrito</button>
                        </Col>
                    </Row>
                    <Row className='bg-black-9 py-3'>
                        <Col className='d-flex align-items-center text-white ml-3'>
                            <b>$ {formatNumber(total)}</b>
                        </Col>
                        <Col className='d-flex justify-content-center pr-4' xs={6}>
                            {total > 0 && <Link className='btn btn-secondary btn-add pl-4 pr-4' to="opciones-de-pago">
                                <FaShoppingCart size={25} />
                                <span className='ml-2'>Ir al Pago</span>
                            </Link>}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ModalCancelCart show={modalCancel} onHide={() => setModalCancel(false)} onSuccess={onCleanCart} />
        </Container>
    )
}

const mapsState = (props) => {
    return {
        cart: props.cart.data,
        fetching_list: props.cart.fetching_list,
        fetching_modify: props.cart.fetching_modify,
        fetching_clean: props.cart.fetching_clean,
    }
}

export default connect(mapsState, { getCart, onModifyItem, onCleanCart })(CartPage);