import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { connect } from 'react-redux';
import { getCart, getTypeMethods, onModifyItem } from '../../redux/cartDuck'
import { onLogin } from '../../redux/userDuck'
import { formatNumber } from '../../utils';
import ItemCartPayment from './item.cart.payment';
import ItemPayment from './item.payment';
import { SocketContext } from '../../context/SocketContext'
import { useHistory } from 'react-router-dom';
import SegmentLoginRegister from './segment.login.register';


const CheckoutPage = ({ getCart, cart, fetching_list, getTypeMethods, fetching_payment, data_payment, branch_office, onModifyItem, fetching_modify,
    loggedIn, onLogin, errors }) => {

    const [detail, setDetail] = useState({ total: 0 })
    const [listCart, setListCart] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [form, setForm] = useState({ observation: '' })
    const history = useHistory();
    const [error, setErrors] = useState(false)

    const { socket } = useContext(SocketContext)

    useEffect(() => {
        if (fetching_modify === 2) {
            getCart()
        }
    }, [fetching_modify])

    useEffect(() => {
        if (fetching_list === 2) {
            setDetail(cart)
            setListCart(cart.detail)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListPayment(data_payment)
        }
    }, [fetching_payment])

    useEffect(() => {
        getCart()
        getTypeMethods()
    }, [])


    useEffect(() => {
        if (socket) {

            socket.on("confirm-cart", (data) => {
                if (data.status === true) {
                    let storage = JSON.parse(localStorage.storage)
                    storage.cart = []
                    localStorage.storage = JSON.stringify(storage)
                    history.push("/confirmacion")
                }
            })
        }

    }, [socket])


    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const onCreateOrder = () => {
        console.log('onCreateOrder');

        if (loggedIn === false) {
            setErrors(true)
            window.location.href = "#register"
        }


        if (socket) {
            let payment = listPayment.filter(row => row.selected === true)[0];
            let { cart, delivery, user } = JSON.parse(localStorage.storage)
            socket.emit("confirm-cart", { branch_office, payment, form, detail: cart, delivery, user })
        } else {
            console.log('no Socket');

        }
    }

    const onChangePayment = (item) => {

        let _pay = listPayment.map(pay => {
            if (pay.id === item.id) {
                pay.selected = true;
            } else {
                pay.selected = false;
            }

            return pay
        })

        setListPayment(_pay)

    }

    const onRemoveItem = (item) => {
        item.quantity = item.quantity - 1;
        onModifyItem(item)
    }

    const onAddItem = (item) => {
        item.quantity = item.quantity + 1;
        onModifyItem(item)
    }

    const onLoginUser = (form) => {
        onLogin(form)
    }


    return (
        <Container>
            <Row className='fixed-top border-bottom d-flex align-items-center bg-white mx-0 my-0'>
                <Col className='text-center pt-2  align-items-center d-flex justify-content-center mx-0'>
                    <h4>Checkout</h4>
                </Col>
            </Row>
            <Row className='mt-5 pt-4 pb-3'>
                <Col><b>Mi Pedido</b></Col>
            </Row>
            <Row>
                <Col>
                    {listCart.map(row => <ItemCartPayment item={row} key={row.id} onRemoveItem={onRemoveItem} onAddItem={onAddItem} />)}
                </Col>
            </Row>
            {loggedIn === false ? <Row>
                <Col className={`bg-white rounded-10 ${error === true ? 'div-error' : ''}`} id="register">
                    <SegmentLoginRegister onSuccess={onLoginUser} errors={errors} />
                </Col>
            </Row> : null}

            <Row className='pt-4 d-flex justify-content-center'>
                <Col className='bg-white rounded-10'>
                    <Row className='pt-3'>
                        <Col xs={8}><b>¿Como deseas pagar?</b></Col>
                        <Col className='text-right justify-content-end d-flex align-items-center'>
                            <div className='title-options-required font-size-13'>Requerido*</div>
                        </Col>
                    </Row>
                    {listPayment.map(param => <ItemPayment item={param} key={param.id} onClick={onChangePayment} />)}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col><b>Recoger En:</b></Col>
            </Row>
            <Row className='my-3'>
                <Col>{branch_office.title} - {branch_office.address}</Col>
                <Col xs={3}>
                    <button className='btn btn-sm btn-info'>Cambiar</button>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col><b>Recomendación</b></Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <textarea className='form-control' name='observation' placeholder='¿Deseas agregar alguna observación?' onChange={updateField}></textarea>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col><b>Resumen General</b></Col>
            </Row>

            <Row className='my-3'>
                <Col xs={9}><b>Total</b></Col>
                <Col>{formatNumber(detail.total)}</Col>
            </Row>
            <Row className='my-5'>
                <Col></Col>
            </Row>

            <Row className='fixed-bottom'>
                <Col className='bg-black-9'>
                    <Row className=' justify-content-center py-3' onClick={() => history.goBack()}>
                        <Col className='ml-3' xs={2}>
                            <AiOutlineArrowLeft color='fff' size={25} />
                        </Col>
                        <Col>
                            <a href='#' className='text-white'>Volver</a>
                        </Col>
                    </Row>
                </Col>
                <Col className='bg-black-9 d-flex align-items-center justify-content-center'>
                    <button className='btn bg-golden text-white rounded-pill px-4 py-2' onClick={onCreateOrder}>Continuar</button>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {

    let { cart, header, user } = props
    return {
        cart: cart.data,
        fetching_list: cart.fetching_list,
        fetching_payment: cart.fetching_payment,
        data_payment: cart.data_payment,
        branch_office: header.branch_office,
        fetching_modify: cart.fetching_modify,
        loggedIn: user.loggedIn,
        errors: user.errors
    }
}

export default connect(mapsState, { getCart, getTypeMethods, onModifyItem, onLogin })(CheckoutPage);