import React from 'react'

const CheckSelected = ({ typeOrder, onClick }) => {
    return (
        <div className='div-selected' onClick={onClick}>
            <div className={`div-selected-content ${typeOrder.type === 2 ? 'active-bg' : ''}`}>
                <div className={`div-selected-img ${typeOrder.type === 2 ? 'active-img' : ''}`}></div>
            </div>
            <div className='div-selected-text font-size-14'>{typeOrder.title}</div>
        </div>
    )
}

export default CheckSelected;