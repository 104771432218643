import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_slug: 0,
    fetching_modifier: 0,
    data: [],
}


const PRODUCT_SLUG = "PRODUCT_SLUG"
const PRODUCT_SLUG_SUCCESS = "PRODUCT_SLUG_SUCCESS"
const RESET_PRODUCT = "RESET_PRODUCT"
const SELECT_MODIFIER = "SELECT_MODIFIER"
const SELECT_MODIFIER_SUCCESS = "SELECT_MODIFIER_SUCCESS"
const FAVORITE = "FAVORITE"
const FAVORITE_SUCCESS = "FAVORITE_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCT_SLUG:
            return { ...state, fetching_slug: 1 }
        case PRODUCT_SLUG_SUCCESS:
            return { ...state, fetching_slug: 2, data: action.payload }
        case RESET_PRODUCT: {
            return { ...state, fetching_slug: 0 }
        }
        case SELECT_MODIFIER: {
            return { ...state, fetching_modifier: 1 }
        }
        case SELECT_MODIFIER_SUCCESS: {
            return { ...state, fetching_modifier: 2, data: action.payload }
        }

        default:
            return state;
    }
}

export let onResetProduct = (slug) => dispatch => {
    dispatch({
        type: RESET_PRODUCT
    })
}

export let getProductSlug = (slug) => dispatch => {
    dispatch({
        type: PRODUCT_SLUG
    })

    let { branch } = JSON.parse(localStorage.getItem("storage"))

    fetch(`${API_URL}/api/products/${slug}/${branch.id}`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        console.log('resp.results', resp.results);

        let { modifier_group, price } = resp.results
        let total = price;

        // if (modifier_group.length > 0) {
        //     modifier_group.map(row => {
        //         if (row.required === true) {
        //             let { modifiers_product } = row;
        //             modifiers_product.map(mod => {
        //                 if (mod.default === true) {
        //                     mod.selected = true
        //                     total = mod.price
        //                 } else {
        //                     mod.selected = false
        //                 }
        //             })
        //         }
        //     })
        // } else {
        //     total = price
        // }

        resp.results.modifier_group = [];
        resp.results.quantity = 1;
        resp.results.total = total;

        dispatch({
            type: PRODUCT_SLUG_SUCCESS,
            payload: resp.results
        })
    })
}


export let selectedTempModifier = (group, modifier) => (dispatch, getState) => {

    dispatch({
        type: SELECT_MODIFIER
    })

    let { data } = getState().product
    let { modifier_group, quantity } = data
    let total = 0;

    if (group.required !== true) {
        let _group = modifier_group.filter(row => row.required == true)[0];
        total = _group.modifiers_product.filter(row => row.selected == true).reduce((total, { price }) => total + parseFloat(price), 0);
    } else {
        modifier_group.filter(row => row.required === false).map(row => {
            let { modifiers_product } = row
            total = + modifiers_product.filter(mod => mod.selected === true).reduce((total, { price }) => total + parseFloat(price), 0)
        })
    }

    modifier_group.map(row => {
        if (row.id === group.id) {
            let { modifiers_product } = row
            modifiers_product.map(mod => {
                if (mod.id === modifier.id) {
                    mod.selected = true;
                    total += parseFloat(mod.price)
                } else {
                    mod.selected = false;
                }
            })
        }
    });

    data.modifier_group = modifier_group
    data.quantity = quantity;
    data.total = total * quantity;

    let data2 = Object.assign({}, data);

    dispatch({
        type: SELECT_MODIFIER_SUCCESS,
        payload: data2
    })
}

export let selectedTempModifierOptional = (group, modifier) => (dispatch, getState) => {

    dispatch({
        type: SELECT_MODIFIER
    })

    let { data } = getState().product
    let { modifier_group, quantity } = data
    let total = 0;


    modifier_group.map(row => {
        let { modifiers_product } = row
        total += modifiers_product.filter(mod => mod.selected === true).reduce((total, { price }) => total + parseFloat(price), 0)
    })

    modifier_group.map(row => {
        if (row.id === group.id) {
            let { modifiers_product } = row
            modifiers_product.map(mod => {
                if (mod.id === modifier.id) {
                    if (mod.selected === true) {
                        mod.selected = false;
                        total -= parseFloat(mod.price)
                    } else {
                        mod.selected = true;
                        total += parseFloat(mod.price)
                    }

                }
            })
        }
    });

    data.modifier_group = modifier_group
    data.total = total * quantity;

    let data2 = Object.assign({}, data);

    dispatch({
        type: SELECT_MODIFIER_SUCCESS,
        payload: data2
    })
}

export let addQuantityTemp = (item) => (dispatch, getState) => {
    dispatch({
        type: SELECT_MODIFIER
    })

    let { quantity } = item;

    let { data } = getState().product

    let { modifier_group, price } = data

    let total = 0;

    if (modifier_group.lenght > 0) {
        modifier_group.map(row => {
            let { modifiers_product } = row
            total += modifiers_product.filter(mod => mod.selected === true).reduce((total, { price }) => total + parseFloat(price), 0)
        })
    } else {
        total = price
    }


    data.quantity = quantity;
    data.total = total * quantity;

    let data2 = Object.assign({}, data);

    dispatch({
        type: SELECT_MODIFIER_SUCCESS,
        payload: data2
    })

}

