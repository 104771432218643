import { getHeaders } from '../utils'
const API_URL = process.env.REACT_APP_API_URL


let initialState = {
    fetching_list: 0,
    data: []
}


const BANNER = "BANNER"
const BANNER_SUCCESS = "BANNER_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case BANNER:
            return { ...state, fetching_list: 1 }
        case BANNER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        default:
            return state;
    }
}

export let getBanners = () => dispatch => {
    dispatch({
        type: BANNER
    })

    fetch(`${API_URL}/api/banner`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: BANNER_SUCCESS,
            payload: resp.results
        })
    })
}