import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ItemDelivery = ({ item, onClick, refLast, targetRefAll, selected }) => {
    let _ref = (refLast !== false) ? refLast : null

    return (
        <Row className='mt-2 d-flex justify-content-center ' ref={_ref} >
            <Col lg={3} className={`mx-2 ${selected.id === item.id ? 'item-category' : ''} `} >
                <Link className="link-category" onClick={() => onClick(item)} to="#">
                    <Row className='my-2'>
                        <Col lg={4} xs={5} className="d-flex align-items-center font-weight-bold font-size-12 text-gray-light">{item.title}</Col>
                        <Col className='text-right text-gray-light'>{item.address}</Col>
                    </Row>

                </Link>
            </Col>
        </Row>
    )
}

export default ItemDelivery;