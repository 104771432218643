import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import icon_local from '../../images/icon_local.svg'
import ItemDelivery from './item.delivery';
import { onSelectBranch, onResetChoose } from '../../redux/branchOfficeDuck'
import { useHistory } from 'react-router-dom';


const DeliveryPage = ({ branch_offices, onSelectBranch, fetching_choose, onResetChoose, fetching_list }) => {

    const history = useHistory()

    const [listBranch, setListBranch] = useState([])
    const targetRef = useRef(null)
    const useScroll = useRef(null)
    const [selected, setSelected] = useState({})
    const [cont, setCont] = useState(0)
    const [ranges, setRanges] = useState([
        { init: 0, end: 12 },
        { init: 12, end: 52 },
        { init: 52, end: 106 },
        { init: 106, end: 158 },
        { init: 158, end: 211 },
        { init: 211, end: 264 },
        { init: 265, end: 311 },
    ])



    const options = {
        rool: null,
        rootMargin: '0px',
        threshold: 0.2
    }


    /* useEffect(() => {
        console.log('llego');

        console.log('useScroll', useScroll);


        useScroll.current.addEventListener('scroll', (event) => {
            let top = event.srcElement.scrollTop
            console.log('top', top);

            if (top > 3) {
                console.log('listbranc', branch_offices);

                setSelected(listBranch[1])
            }

        });

    }, [useScroll]) */



    const callbackFunction = entries => {
        const [entry] = entries
        if (entry.isIntersecting) {

            let cont = branch_offices[branch_offices.length - 1].end


            let _branch_offices = branch_offices.map((b, k) => {
                b.init = cont
                cont += 42
                b.end = cont
                return b;
            })

            let _branch = listBranch.concat(_branch_offices)

            setListBranch(_branch)
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options);
        const currentTarget = targetRef.current;

        if (currentTarget) observer.observe(currentTarget)

        return () => {
            if (currentTarget) observer.unobserve(currentTarget);
        }
    }, [targetRef, options])

    const getViewBranch = top => {
        console.log('listBranch', listBranch);

        branch_offices.map(r => {
            if (top > r.init && top < r.end) {
                setSelected(r)
            }
        })
        console.log('position', top);

    }


    useEffect(() => {
        if (fetching_list === 2) {
            let cont = 0;
            branch_offices = branch_offices.map((b, k) => {
                b.init = cont
                cont += 42
                b.end = cont
                return b;
            })

            console.log('branch_offices', branch_offices);

            setListBranch(branch_offices)
            setSelected(branch_offices[1])


            useScroll.current.addEventListener('scroll', (event) => {
                let top = event.srcElement.scrollTop
                getViewBranch(top)
            });
        }
    }, [fetching_list])

    useEffect(() => {
        onResetChoose()
    }, [])

    useEffect(() => {

        if (fetching_choose === 2) {
            history.push("/menu")
        }
    }, [fetching_choose])

    const onClick = (item) => {
        onSelectBranch(item)
    }

    return (
        <Container fluid className='mx-0 px-0'>
            <div className='bg-main'>
                <div className='bg-burger-before'>
                    <div className='container-body-icon'>
                        <Container fluid>
                            <Row className='my-4 d-flex justify-content-center my-1'>
                                <Col className='text-center' xs={5} lg={1}>
                                    <img src={icon_local} className="img-fluid" />
                                </Col>
                            </Row>
                        </Container>

                    </div>
                    <div className='container-footer-location' ref={useScroll}>
                        <div className='header-blur'></div>
                        <Container fluid>
                            {listBranch.map((row, k) => <ItemDelivery selected={selected} item={row} key={k} onClick={onClick}
                                refLast={listBranch.length - 1 === k ? targetRef : false} />)}
                        </Container>
                        <div className='footer-blur'></div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

const mapsState = (props) => {
    let { branch_office, user } = props
    return {
        branch_offices: branch_office.data,
        fetching_choose: branch_office.fetching_choose,
        fetching_list: branch_office.fetching_list,
        selected: branch_office.selected,
        loggedInVisit: user.loggedInVisit
    }
}

export default connect(mapsState, { onSelectBranch, onResetChoose })(DeliveryPage);