import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_save: 0,
    data: [],
    row:{}
}


const LIST_FAVORITE = "LIST_FAVORITE"
const LIST_FAVORITE_SUCCESS = "LIST_FAVORITE_SUCCESS"
const SET_FAVORITE = "SET_FAVORITE"
const SET_FAVORITE_SUCCESS = "SET_FAVORITE_SUCCESS"



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_FAVORITE:
            return { ...state, fetching_list: 1 }

        case LIST_FAVORITE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case SET_FAVORITE:
            return { ...state, fetching_save: 1 }
        case SET_FAVORITE_SUCCESS:
            return { ...state, fetching_save: 2,row:action.payload }
        default:
            return state;
    }
}

export let getFavorites = () => dispatch => {
    dispatch({
        type: LIST_FAVORITE
    })

    fetch(`${API_URL}/api/favorites`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_FAVORITE_SUCCESS,
            payload: resp.results
        })
    })
}

export let setFavoriteUser = (item) => dispatch => {
    dispatch({
        type: SET_FAVORITE
    })

    fetch(`${API_URL}/api/favorites/${item.id}`, {
        method: "PUT",
        body: JSON.stringify(item),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        console.log('resp',resp);
        
        dispatch({
            type: SET_FAVORITE_SUCCESS,
            payload:resp
        })
    })



}