import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BtnDrawer = ({ title, icon, onClick }) => {
    return (
        <Row onClick={onClick} className="py-1">
            <Col className='d-flex justify-content-start align-items-center' xs={4}>
                {icon}
            </Col>
            <Col xs={8} className="text-center">{title}</Col>
        </Row>
    )
}

export default BtnDrawer;