import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_choose: 0,
    fetching_option: 0,
    data: [],
    selected: {},
    path: ''
}


const LIST_BRANCH_OFFICE = "LIST_BRANCH_OFFICE"
const LIST_BRANCH_OFFICE_SUCCESS = "LIST_BRANCH_OFFICE_SUCCESS"
const CHOOSE_BRANCH_OFFICE = "CHOOSE_BRANCH_OFFICE"
const CHOOSE_BRANCH_OFFICE_SUCCESS = "CHOOSE_BRANCH_OFFICE_SUCCESS"
const CHOOSE_RESET = "CHOOSE_RESET"
const CHOOSE_OPTION = "CHOOSE_OPTION"
const CHOOSE_OPTION_SUCCESS = "CHOOSE_OPTION_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_BRANCH_OFFICE:
            return { ...state, fetching_list: 1 }
        case LIST_BRANCH_OFFICE_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CHOOSE_BRANCH_OFFICE:
            return { ...state, fetching_choose: 1 }
        case CHOOSE_BRANCH_OFFICE_SUCCESS:
            return { ...state, fetching_choose: 2, selected: action.payload }
        case CHOOSE_RESET:
            return { ...state, fetching_choose: 0, fetching_option: 0 }
        case CHOOSE_OPTION:
            return { ...state, fetching_option: 1 }
        case CHOOSE_OPTION_SUCCESS:
            return { ...state, fetching_option: 2, path: action.payload }
        default:
            return state;
    }
}

export let onResetChoose = (item) => dispatch => {
    dispatch({
        type: CHOOSE_RESET
    })
}


export let onSelectBranch = (item) => dispatch => {

    dispatch({
        type: CHOOSE_BRANCH_OFFICE
    })

    localStorage.setItem("storage", JSON.stringify({ branch: item, delivery: 2 }))

    dispatch({
        type: CHOOSE_BRANCH_OFFICE_SUCCESS,
        payload: item
    })
}

export let onSelectDelivery = (path) => dispatch => {

    dispatch({
        type: CHOOSE_OPTION
    })

    localStorage.setItem("storage", JSON.stringify({ delivery: path }))

    dispatch({
        type: CHOOSE_OPTION_SUCCESS,
        payload: path,
    })
}

export let getBranchOffice = () => dispatch => {
    dispatch({
        type: LIST_BRANCH_OFFICE
    })

    fetch(`${API_URL}/api/branch-office`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_BRANCH_OFFICE_SUCCESS,
            payload: resp.results
        })
    })
}