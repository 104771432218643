import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Dot from '../../components/dot';

const ItemPayment = ({ item,onClick }) => {
    let { parameter } = item

    return (
        <Row className='my-3'>
            <Col xs={10}>
                <Row>
                    <Col>{parameter.description}</Col>
                </Row>
                <Row>
                    <Col className='text-muted font-size-11'>({item.number})</Col>
                </Row>
            </Col>
            <Col>
                <Dot checked={item.selected} onClick={() => onClick(item)} />
            </Col>
        </Row>
    )
}

export default ItemPayment;