import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Dot from '../../components/dot';
import Square from '../../components/square';
import { formatNumber } from '../../utils';

const ItemModifier = ({ item, type_input, onClick }) => {
    let price = item.price === 0 || item.price == '0' ? '' : item.price
    
    let component = type_input === 1 ? <Dot checked={item.selected} onClick={() => onClick(item)} /> : <Square selected={item.selected} onClick={() => onClick(item)}/>
    return (
        <>
            <Row className='mt-4'>
                <Col className='font-size-12' xs={6}>{item.title}</Col>
                <Col className='font-size-12' xs={4}>{formatNumber(price)}</Col>
                <Col className='font-size-12' xs={2}>
                    {component}
                </Col>
            </Row>
            <Row>
                <Col><hr /></Col>
            </Row>
        </>

    )
}

export default ItemModifier;