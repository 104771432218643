import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { BsFillFileTextFill, BsFillPinMapFill, BsHeartFill, BsFillCartFill, BsFillFilePersonFill } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import BtnDrawer from './btn.drawer';
import logo_white from '../images/logo_mana_white.png'
import { useHistory } from 'react-router-dom';


const DrawerSegment = ({ drawer, onToggleDrawable, user }) => {

    let { loggedIn, } = user
    const history = useHistory()

    const goToOption = (path) => {
        onToggleDrawable()
        history.push(path)
    }

    console.log('user', user);


    return (
        <Container fluid className={`${drawer === false ? 'd-none' : ''}`}>
            <Row>
                <Col className={`page-show pb-4 pl-0 pr-0 bg-white container-cart page`} xs={10} lg={3}>
                    <Row>
                        <Col className='py-2 px-4'>
                            <button className='close button-style'>
                                <IoClose color='black' size={40} onClick={() => onToggleDrawable()} />
                            </button>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center mt-4'>
                        <Col xs={5}>
                            <img src={logo_white} className="img-fluid" />
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Col>
                            <Row>
                                <Col className='text-center'>Bienvenido</Col>
                            </Row>
                            <Row className='my-2'>
                                <Col className='text-center'><h5>{loggedIn === false ? 'Cliente' : user.user.email}</h5></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='d-flex justify-content-center mt-4 '>
                        <Col xs={8} className="border-bottom">
                            <BtnDrawer title={"Ir al Menu"} icon={<BsFillCartFill />} onClick={() => goToOption("/menu")} />
                        </Col>
                    </Row>
                    {loggedIn !== false ? <>
                        <Row className='d-flex justify-content-center mt-4'>
                            <Col xs={8} className="border-bottom">
                                <BtnDrawer title={"Mis Pedidos"} icon={<BsFillFileTextFill />} onClick={() => goToOption("/pedidos")} />
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-center mt-3'>
                            <Col xs={8} className="border-bottom">
                                <BtnDrawer title={"Mis Direcciones"} icon={<BsFillPinMapFill />} onClick={() => goToOption("/direcciones")} />
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-center mt-3'>
                            <Col xs={8} className="border-bottom">
                                <BtnDrawer title={"Favoritos"} icon={<BsHeartFill />} onClick={() => goToOption("/favoritos")} />
                            </Col>
                        </Row>
                    </> : <Row className='d-flex justify-content-center mt-3'>
                        <Col xs={8} className="border-bottom">
                            <BtnDrawer title={"Login o Registro"} icon={<BsFillFilePersonFill />} onClick={() => goToOption("/login")} />
                        </Col>
                    </Row>}


                </Col>
            </Row>
        </Container>
    )
}

export default DrawerSegment;